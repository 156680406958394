import React from 'react';
import { Language } from '../App';
import '../App.css';
import {dict, privacy, terms, cookies} from '../utils/dict'

type PolicyProps ={
    lang: Language
    policy: 'privacy' | 'terms' | 'cookies'
}

export default function Policy(props: PolicyProps) {
    return(
        <div className="privacy-container">
            <header className="site-header">
                {dict[props.policy][props.lang]}
            </header>
            {(props.policy === 'privacy' ? privacy : props.policy === 'terms' ? terms : cookies).map(p=>
                <div>
                    <header className="subheader">
                        {p.header[props.lang]}
                    </header>
                    <p>
                        {p.paragraph[props.lang]}
                    </p>
                </div>
                )}
        </div>
    )
}
