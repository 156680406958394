export const FEMALE = 0
export const MALE = 1
export const YES = 1
export const NO = 0
export const LOW = 0
export const HIGH = 1
export const PL = 2

export const category = {
    ticks: [0, 1, 5, 10, 100],
    colors: ['green', 'gold', 'red', 'brown'],
};

export type Cases = 0 | 1 | 2

export type ScoreProfile = {
    [key: string]: boolean | number
    gender: number,
    smoking: number,
    age: number,
    pressure: number,
    cholesterol: number,
    country: Cases
}

export type FullProfile = ScoreProfile & {
    cholUnit: number,
    ldl: number,
    cvEvent: boolean
    diastolic: number
    diabetes: boolean,
    diabetesDuration: Cases
    diabetesComplications: number
    kidneys: boolean
    kidneysWhich: Cases
    fh: boolean
}

export const BaseUser: ScoreProfile = {
    gender: MALE,
    smoking: NO,
    age: 40,
    pressure: 120,
    cholesterol: 4,
    country: PL,
}

export type ScoreTable = (ScoreProfile & {
    risk: number
})[]

export const SCORE = [
    {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 7,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 8,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 8,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 9,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 11,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 11,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 12,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 13,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 12,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 14,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 15,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 17,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 18,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 20,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 22,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 24,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 6,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 6,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 7,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 7,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 9,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 9,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 10,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 11,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 10,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 11,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 13,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 14,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 15,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 16,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 18,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 20,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 5,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 5,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 6,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 6,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 7,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 8,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 8,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 9,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 8,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 9,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 10,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 12,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 12,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 13,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 15,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 17,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 4,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 4,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 5,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 5,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 6,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 6,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 7,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 7,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 7,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 8,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 9,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 10,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 10,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 11,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 12,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 14,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 4,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 4,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 5,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 5,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 7,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 7,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 8,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 9,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 8,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 9,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 10,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 12,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 12,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 14,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 16,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 18,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 3,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 3,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 4,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 4,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 5,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 6,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 6,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 7,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 6,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 7,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 8,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 9,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 9,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 11,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 12,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 14,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 2,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 3,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 3,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 3,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 4,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 4,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 5,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 5,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 5,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 5,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 6,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 7,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 7,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 8,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 9,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 11,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 2,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 2,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 2,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 2,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 3,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 3,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 3,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 4,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 3,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 4,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 5,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 5,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 5,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 6,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 7,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 8,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 2,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 3,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 3,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 3,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 4,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 5,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 5,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 6,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 5,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 6,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 7,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 8,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 8,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 10,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 11,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 13,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 2,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 2,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 2,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 2,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 3,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 3,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 4,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 4,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 4,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 4,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 5,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 5,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 6,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 7,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 8,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 9,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 2,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 2,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 2,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 3,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 3,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 3,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 3,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 3,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 4,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 4,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 5,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 6,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 7,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 1,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 2,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 2,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 2,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 2,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 2,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 2,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 2,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 3,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 3,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 4,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 4,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 5,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 2,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 2,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 3,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 3,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 3,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 4,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 3,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 4,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 4,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 5,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 6,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 7,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 8,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 9,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 1,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 2,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 2,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 2,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 3,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 2,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 2,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 3,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 3,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 4,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 4,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 5,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 6,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 1,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 2,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 2,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 2,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 2,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 3,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 3,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 3,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 4,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 0,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 0,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 0,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 1,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 1,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 2,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 2,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 2,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 2,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 3,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 1,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 2,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 2,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 2,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 3,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 2,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 2,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 3,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 3,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 4,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 5,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 5,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 6,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 0,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 0,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 1,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 2,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 2,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 2,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 2,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 3,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 3,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 4,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 0,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 0,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 0,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 0,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 1,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 1,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 2,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 2,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 3,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 0,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 0,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 0,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 0,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 0,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 0,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 0,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 0,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 2,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 0,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 0,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 0,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 0,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 1,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 1,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 2,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 2,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 3,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 3,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 0,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 0,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 0,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 0,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 0,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 0,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 0,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 0,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 0,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 2,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 1,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 1,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 1,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 1,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 4,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 5,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 6,
        country: LOW,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 1,
        cholesterol: 7,
        country: LOW,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 12,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 13,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 14,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 15,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 17,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 19,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 20,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 21,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 24,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 26,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 30,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 33,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 33,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 36,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 40,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 45,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 10,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 11,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 12,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 13,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 14,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 15,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 16,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 18,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 20,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 22,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 25,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 28,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 27,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 31,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 34,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 39,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 8,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 9,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 10,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 10,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 12,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 13,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 14,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 15,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 16,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 18,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 21,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 24,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 23,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 26,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 29,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 33,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 7,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 7,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 8,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 9,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 10,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 10,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 11,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 12,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 13,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 15,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 17,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 20,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 19,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 22,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 25,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 28,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 7,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 8,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 8,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 9,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 11,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 12,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 13,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 15,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 15,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 17,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 20,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 23,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 23,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 26,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 30,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 34,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 5,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 6,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 6,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 7,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 9,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 9,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 10,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 11,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 12,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 14,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 16,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 18,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 18,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 21,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 24,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 27,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 4,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 4,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 5,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 5,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 7,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 7,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 8,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 9,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 9,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 11,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 12,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 14,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 14,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 16,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 19,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 22,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 3,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 3,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 4,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 4,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 5,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 5,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 6,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 7,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 7,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 8,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 10,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 11,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 11,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 13,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 15,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 17,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 4,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 4,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 5,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 5,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 7,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 8,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 9,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 10,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 10,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 11,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 13,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 15,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 16,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 19,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 22,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 25,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 3,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 3,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 3,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 4,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 5,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 6,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 6,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 7,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 7,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 8,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 10,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 11,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 12,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 14,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 16,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 19,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 2,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 2,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 2,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 3,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 4,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 4,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 4,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 5,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 5,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 6,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 7,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 8,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 9,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 10,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 12,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 14,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 1,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 1,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 2,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 2,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 3,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 3,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 3,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 3,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 4,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 4,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 5,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 6,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 6,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 7,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 9,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 10,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 2,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 2,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 3,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 3,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 5,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 5,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 6,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 7,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 6,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 7,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 9,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 10,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 11,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 13,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 16,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 18,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 1,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 2,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 2,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 2,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 3,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 3,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 4,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 4,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 4,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 5,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 6,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 7,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 8,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 9,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 11,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 13,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 1,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 1,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 1,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 1,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 2,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 2,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 2,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 3,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 3,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 3,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 4,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 5,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 5,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 6,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 7,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 9,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 1,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 1,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 1,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 1,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 1,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 1,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 2,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 2,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 2,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 2,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 3,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 3,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 4,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 4,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 5,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 6,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 1,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 1,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 2,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 2,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 3,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 3,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 4,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 4,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 4,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 5,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 6,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 7,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 8,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 9,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 11,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 13,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 1,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 1,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 1,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 1,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 2,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 2,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 2,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 3,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 2,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 3,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 3,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 4,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 5,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 6,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 7,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 9,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 0,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 0,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 1,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 1,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 1,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 1,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 1,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 2,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 2,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 2,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 2,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 3,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 3,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 4,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 5,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 6,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 0,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 0,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 0,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 0,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 2,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 2,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 2,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 3,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 4,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 0,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 0,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 1,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 1,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 1,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 1,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 2,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 2,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 2,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 2,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 2,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 3,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 4,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 4,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 5,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 7,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 0,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 0,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 0,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 0,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 2,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 2,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 2,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 3,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 4,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 1,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 1,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 1,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 1,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 1,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 1,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 2,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 2,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 1,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 1,
        cholesterol: 4,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 1,
        cholesterol: 5,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 1,
        cholesterol: 6,
        country: HIGH,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 1,
        cholesterol: 7,
        country: HIGH,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 17,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 20,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 24,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 28,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 12,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 14,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 17,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 20,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 8,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 10,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 12,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 14,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 6,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 7,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 8,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 10,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 9,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 10,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 12,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 15,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 6,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 7,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 9,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 10,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 4,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 5,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 6,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 7,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 3,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 3,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 4,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 5,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 5,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 6,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 7,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 8,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 3,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 4,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 5,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 5,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 2,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 3,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 3,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 4,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 2,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 2,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 2,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 3,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 3,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 3,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 4,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 4,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 2,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 2,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 3,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 3,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 1,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 1,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 2,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 2,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 1,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 1,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 1,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 1,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 2,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 2,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 2,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 3,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 1,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 1,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 2,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 2,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 1,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 1,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 1,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 1,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 1,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 1,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 1,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 1,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 0,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 0,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 1,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 28,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 33,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 38,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 180,
        risk: 43,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 20,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 24,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 28,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 160,
        risk: 32,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 14,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 17,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 20,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 140,
        risk: 24,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 10,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 12,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 14,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 70,
        pressure: 120,
        risk: 17,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 17,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 20,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 24,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 180,
        risk: 28,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 12,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 14,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 17,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 160,
        risk: 20,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 9,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 10,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 12,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 140,
        risk: 14,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 6,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 7,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 9,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 65,
        pressure: 120,
        risk: 10,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 11,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 13,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 15,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 180,
        risk: 18,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 8,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 9,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 11,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 160,
        risk: 13,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 5,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 6,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 8,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 140,
        risk: 9,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 4,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 4,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 5,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 60,
        pressure: 120,
        risk: 6,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 7,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 8,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 10,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 180,
        risk: 12,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 5,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 6,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 7,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 160,
        risk: 8,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 3,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 4,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 5,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 140,
        risk: 6,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 2,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 3,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 3,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 55,
        pressure: 120,
        risk: 4,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 5,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 6,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 7,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 180,
        risk: 8,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 3,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 4,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 5,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 160,
        risk: 6,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 2,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 3,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 3,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 140,
        risk: 4,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 2,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 2,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 2,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 50,
        pressure: 120,
        risk: 3,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 2,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 2,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 3,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 180,
        risk: 3,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 2,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 2,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 160,
        risk: 2,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 1,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 1,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 1,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 140,
        risk: 2,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 1,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 1,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 1,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: NO,
        age: 40,
        pressure: 120,
        risk: 1,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 32,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 37,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 43,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 49,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 23,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 27,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 32,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 37,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 17,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 20,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 23,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 27,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 12,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 14,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 17,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 20,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 17,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 20,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 24,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 28,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 12,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 14,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 17,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 20,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 8,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 10,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 12,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 14,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 6,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 7,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 8,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 10,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 9,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 11,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 13,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 15,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 6,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 8,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 9,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 11,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 5,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 5,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 6,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 8,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 3,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 4,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 4,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 5,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 5,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 6,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 8,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 9,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 4,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 4,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 5,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 6,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 3,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 3,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 4,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 4,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 2,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 2,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 3,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 3,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 3,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 4,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 5,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 5,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 2,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 3,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 3,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 4,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 2,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 2,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 2,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 3,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 1,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 2,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 2,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 1,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 2,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 2,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 2,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 1,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 2,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 1,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 1,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 1,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 1,
        cholesterol: 7,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 0,
        cholesterol: 4,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 1,
        cholesterol: 5,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 1,
        cholesterol: 6,
        country: PL,
    }, {
        gender: FEMALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 1,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 49,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 56,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 62,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 180,
        risk: 69,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 37,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 43,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 49,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 160,
        risk: 55,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 27,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 32,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 37,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 140,
        risk: 43,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 20,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 23,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 27,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 70,
        pressure: 120,
        risk: 32,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 32,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 37,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 43,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 180,
        risk: 49,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 23,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 27,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 32,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 160,
        risk: 37,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 17,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 20,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 23,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 140,
        risk: 27,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 12,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 14,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 17,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 65,
        pressure: 120,
        risk: 20,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 21,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 25,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 29,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 180,
        risk: 34,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 15,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 18,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 21,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 160,
        risk: 25,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 11,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 13,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 15,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 140,
        risk: 18,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 7,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 9,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 11,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 60,
        pressure: 120,
        risk: 13,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 14,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 17,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 20,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 180,
        risk: 23,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 10,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 12,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 14,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 160,
        risk: 17,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 7,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 8,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 10,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 140,
        risk: 12,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 5,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 6,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 7,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 55,
        pressure: 120,
        risk: 8,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 9,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 11,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 13,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 180,
        risk: 16,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 6,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 8,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 9,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 160,
        risk: 11,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 5,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 5,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 6,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 140,
        risk: 8,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 3,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 4,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 5,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 50,
        pressure: 120,
        risk: 5,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 4,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 4,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 5,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 180,
        risk: 6,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 3,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 3,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 4,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 160,
        risk: 4,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 2,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 2,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 3,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 140,
        risk: 3,
        cholesterol: 7,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 1,
        cholesterol: 4,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 1,
        cholesterol: 5,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 2,
        cholesterol: 6,
        country: PL,
    }, {
        gender: MALE,
        smoking: YES,
        age: 40,
        pressure: 120,
        risk: 2,
        cholesterol: 7,
        country: PL,
    }]
