import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { Language } from '../App';
import '../App.css';
import { dict } from '../utils/dict';
import { BaseUser, category, FullProfile, NO, SCORE, ScoreTable } from '../utils/SCORE';
import Graph from './Graph';
import InfoDialog from './InfoDialog';


export default function Result(props: {lang: Language, user: FullProfile }) {
    const { user } = props
    const { cholUnit, ldl, cvEvent, diastolic, diabetes, diabetesDuration, diabetesComplications, kidneys, kidneysWhich, fh } = user
    
    const userID = (SCORE as ScoreTable).findIndex(d=>Object.keys(BaseUser).every(k=>user[k]===d[k]))
    const score = userID === -1 ? -1: SCORE[userID].risk;
    const riskAge = userID === -1 ? undefined: 
        SCORE
            .filter(d=>
                d.gender === user.gender && d.country === user.country &&
                d.smoking===NO && d.pressure === 120 && d.cholesterol ===4 && 
                d.risk >= score
            )
            .reduce((a,c)=>c.age < a ? c.age : a, 75 )

    let factors=[{
        en: 'CV event/disease',
        pl: 'Zdarzenie/choroba sercowo-naczyniowa',
        isThere: cvEvent,
        very: true
    },{
        en: 'Severe chronic kindey disease',
        pl: 'Ciężka przewlekła choroba nerek',
        isThere: kidneys && kidneysWhich===2,
        very: true
    },{
        en: 'Moderate chronic kindey disease',
        pl: 'Umiarkowana przewlekła choroba nerek',
        isThere: kidneys && kidneysWhich===1,
        very: false
    },{
        en: 'Family hypercholesterolemia with other major risk factors',
        pl: 'Hiperholesterolemia rodzinna z innymi czynnikami wysokiego ryzyka',
        isThere: false,
        very: true
    },{
        en: 'Family hypercholesterolemia without other major risk factors',
        pl: 'Hiperholesterolemia rodzinna bez innych czynników wysokiego ryzyka',
        isThere: false,
        very: false
    },{
        en: 'High systolic BP',
        pl: 'Wysokie ciśnienie skurczowe',
        isThere: user.pressure===200,
        very: false
    },{
        en: 'High diastolic BP',
        pl: 'Wysokie ciśnienie rozkurczowe',
        isThere: diastolic,
        very: false
    },{
        en: 'High total cholesterol',
        pl: 'Wysoki cholesterol całkowity',
        isThere: user.cholesterol===8,
        very: false
    },{
        en: 'High LDL cholesterol',
        pl: 'Wysoki cholesterol LDL',
        isThere: ldl,
        very: false
    }]

    if(diabetes){
        if(diabetesComplications){
            factors.push({
                en: 'Diabetes with organ damage',
                pl: 'Cukrzyca z uszkodzeniami narządowymi',
                isThere: true,
                very: true
            })
        } else{
            if(diabetesDuration===2){
                factors.push({
                    en: 'Diabetes with duration > 20 years',
                    pl: 'Cukrzyca trwająca >20 lat',
                    isThere: true,
                    very: true
                })
            } else {
                let factorsNumber = factors.reduce((a,c)=>c.isThere ? a+1 : a,0) + (fh ? 1 : 0)
                if(factorsNumber>=3){
                    factors.push({
                        en: 'Diabetes with 3 or more other major risk factors',
                        pl: 'Cukrzyca z 3 lub więcej innymi czynnikami wysokiego ryzyka',
                        isThere: true,
                        very: true
                    })
                } else {
                    if(factorsNumber>0){
                        factors.push({
                            en: 'Diabetes with other major risk factor',
                            pl: 'Cukrzyca z innym czynnikiem wysokiego ryzyka',
                            isThere: true,
                            very: false
                        })
                    } else {
                        if(diabetesDuration===1){
                            factors.push({
                                en: 'Diabetes with duration > 10 years',
                                pl: 'Cukrzyca trwająca >10 lat',
                                isThere: true,
                                very: false
                            })
                        }
                    }
                }
            }
        }
    }

    let sick = factors.reduce((a,c)=>c.isThere ? a+1 : a,0)> 0
    if(fh){
        if(sick){
            factors[3].isThere = true
        } else {
            factors[4].isThere = true
        }
        sick=true
    }
    let verySick = factors.reduce((a,c)=>c.very && c.isThere ? a+1 : a, 0)
    let notVerySick = factors.reduce((a,c)=>!c.very && c.isThere ? a+1 : a,0)
    const risk = sick ? verySick ? 3 : 2 : score >= 10 ? 3 : score >=5 ? 2 : score >=1 ? 1 : 0;
    const scoreInfo = [...dict.scoreInfoList[props.lang], sick ? dict.scoreNotInfo[props.lang] : '']  
  
    return(
        <React.Fragment>
            <div className="flexChild margin subsection">
                <header className="subheader center narrowMargin">
                    {dict.results[props.lang]}
                    <InfoDialog
                        header={dict.scoreHeader[props.lang]}
                        items={scoreInfo}
                    />
                </header>

                <Paper className="table">
                    <Table aria-label="simple table" >
                        <TableBody>
                            {!sick &&<TableRow>
                                <TableCell component="th" scope="row">
                                    {dict.score[props.lang]}
                                </TableCell>
                                <TableCell align="right">
                                    <b> {score===0 ? '< 1%' : score+'%'} </b>
                                </TableCell>
                            </TableRow>}
                            {!sick &&<TableRow>
                                <TableCell component="th" scope="row">
                                    {dict.scoreCV[props.lang]}
                                </TableCell>
                                <TableCell align="right">
                                    <b>{score===0 ? '< 1%' : (score * (4-(user.gender ? 1 : 0))) +'%'}</b>
                                </TableCell>
                            </TableRow>} 
                            {!sick && user.age <70 &&<TableRow>
                                <TableCell component="th" scope="row">
                                    {dict.riskAge[props.lang]}
                                </TableCell>
                                <TableCell align="right">
                                    <b>{riskAge === 75 ? '>70' : riskAge}</b>
                                </TableCell>
                            </TableRow>} 
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {dict.risk[props.lang]}
                                </TableCell>
                                <TableCell align="right">
                                    <span style={{color: category.colors[risk]}}><b>
                                        {dict.labels[props.lang][risk]}
                                    </b></span>
                                </TableCell>
                            </TableRow>                
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {dict.target[props.lang]}
                                </TableCell>
                                <TableCell align="right">
                                    <b>{cholUnit ? dict.targetLevelsMg[props.lang][risk]:dict.targetLevelsMmol[props.lang][risk]}</b>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>

                {sick ? 
                    <React.Fragment>
                        {verySick ?  
                        <List
                            subheader={
                            <ListSubheader>
                                {dict.factorsVHighHeader[props.lang]}                      
                            </ListSubheader>
                            }>
                            
                            {factors
                                .filter(f=>f.isThere && f.very)
                                .map(f=>
                                    <ListItem>
                                        {f[props.lang]}
                                    </ListItem>)
                                
                                }
            
                            </List> : null
                        }

                        <Divider/>

                        {notVerySick ?  
                            <List
                                subheader={
                                <ListSubheader>
                                    {dict.factorsHighHeader[props.lang]}                       
                                </ListSubheader>
                            }>
                                {factors
                                    .filter(f=>f.isThere && !f.very)
                                    .map(f=>
                                        <ListItem>
                                            {f[props.lang]}
                                        </ListItem>)
                                    }
                                    
                            </List> :null}
                    </React.Fragment>
                    :
                    <Graph lang={props.lang} user={user} />
                }
            </div>
        </React.Fragment>
    )
}
