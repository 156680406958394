import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import InfoIcon from '@material-ui/icons/Info';
import React, { useState } from 'react';

type InfoProps ={
    header: string
    items: string []
}

export default function CountryInfo(props: InfoProps) {
    const [dialog, setDialog] = useState(false)

    return(
        <>
            <IconButton onClick={()=>setDialog(true)}>
                <InfoIcon/>
            </IconButton>

            <Dialog 
                open={dialog}
                onClose={()=>setDialog(false)} 
                aria-labelledby="simple-dialog-title" 
                >
                    <List
                        subheader={
                            <ListSubheader>
                                {props.header}                      
                            </ListSubheader>
                        }>

                        {props.items.map((f,i)=>
                            <ListItem key={i}>
                                {f}
                            </ListItem>)             
                        }
                    </List>
            </Dialog>
        </>
    )
}
