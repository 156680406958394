import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import '../App.css';

const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
}));

type SelectProps = {
    name: string
    value: number
    options: {
        value: number
        label: string
    }[]
    onChange(e:any): void
}

export default function Risk({
    name,
    value,
    options,
    onChange
}: SelectProps) {
    const classes = useStyles();
    // const onOptionChange = (a: any) =>onChange({[value]: a.target.value as number})
  
    return(
        <FormControl className={classes.formControl}>
            <InputLabel>{name}</InputLabel>
            <Select
                value={value}
                onChange={onChange}
                >
                {options.map(option=>
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>    
                )}
            </Select>
        </FormControl>
    )
}
