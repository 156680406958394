export const dict = {
    age: {en: 'Age', pl: 'Wiek'},
    calculator:{en:'Cardiovascular (CV) risk calculator', pl: 'Kalkulator ryzyka sercowo-naczyniowego (SN)'},
    contact: {en: 'Contact', pl: 'Kontakt'},
    cookies:{en: 'Cookie policy', pl:'Polityka cookie'},
    cookiesBar: {en: 'This website is using cookies for Google Analytics', pl: 'Strona używa plików cookies do Google Analytics'},
    country: {en:'Country', pl:'Kraj'},
    countryHeader: {en: 'European country risk classification', pl:'Klasyfikacja ryzyka krajów Europejskich'},
    countryList: {
        en: ['LOW RISK countries: Andorra, Austria, Belgium, Cyprus, Denmark, Finland, France, Germany, Greece, Iceland, Ireland, Israel, Italy, Luxembourg, Malta, Monaco, The Netherlands, Norway, Portugal, San Marino, Slovenia, Spain, Sweden, Switzerland and the United Kingdom.',
            'HIGH RISK countries: Albania, Algeria, Armenia, Bosnia and Herzegovina, Croatia, Czech Republic, Estonia, Hungary, Latvia, Lebanon, Libya, Lithuania, Montenegro, Morocco, Poland, Romania, Serbia, Slovakia, Tunisia, and Turkey',
            'VERY HIGH RISK countries (choose high risk but the risk can be underestimated): Azerbaijan, Belarus, Bulgaria, Egypt, Georgia, Kazakhstan, Kyrgyzstan, North Macedonia, Republic of Moldova, Russian Federation, Syria, Tajikistan, Turkmenistan, Ukraine, and Uzbekistan'], 
        pl: ['NISKIE RYZYKO: Andorra, Austria, Belgia, Cypr, Dania, Finlandia, Francja, Greecja, Hiszpania, Holandia, Icelandia, Irelandia, Izrael, Luxemburg, Malta, Monako, Niemcy, Norwegia, Portugalia, San Marino, Słowenia, Szwajcaria, Szwecja, Włochy i Zjednoczone Królestwo.',
            'WYSOKIE RYZYKO: Albania, Algeria, Armenia, Bośnia i Herzegovina, Chorwacja, Czechy, Czarnogóra, Estonia, Liban, Libia, Litwa, Łotwa, Maroko, Polska, Rumunia, Serbia, Słowacjaa, Tunezja, Turcja i Węgry',
            'BARDZO WYSOKIE RYZYKO (wybierz wysokie ale ryzyko może być niedoszacowane): Azerbejdżan, Białoruś, Bułgaria, Egipt, Gruzja, Kazachstan, Kirgistan, Macedonia Północna, Mołdawia, Rosja, Syria, Tadżykistan, Turkmenistan, Ukraina, and Uzbekistan']},
    cholesterol: {en:'Total cholesterol', pl: 'Cholesterol całkowity'},
    cholesterolLDL: {en:'LDL-Cholesterol', pl: 'Cholesterol LDL'},
    cholesterolUnit: {en:'Cholesterol unit', pl: 'Jednostka cholesterolu'},
    cvEvent: {en: 'Documented cardiovascular disease ', 
        pl:'Udokumentowana choroba serca lub naczyń'},
    cvEventHeader: {en:'Documented CV diseases:', pl:'Udokumentowane choroby serca lub naczyń:'},
    cvEventList: {
        en: ['Acute coronary syndromes: myocardial infarction (heart attack) or unstable angina',
            'Stable angina', 
            'Revascularization: percutaneous coronary intervention, coronary artery bypass graft surgery and other revascularization procedures',
            'Stroke or TIA (transient ischaemic attack)',
            'Peripheral artery disease',
            'Significant plaque on coronary angiography, CT scan or on carotid ultrasound'], 
        pl:['Ostre zespoły wieńcowe: zawał serca lub niestabilna dusznica bolesna',
            'Stabilna dusznica bolesna (dławica piersiowa, choroba wieńcowa)',
            'Rewaskularyzacja (poszerzenie i udrożnienie zwężonego naczynia krwionośnego): przezskórna interwencja wieńcowa, pomostowanie aortalno-wieńcowe (tzw. bajpasy) i inne procedury rewaskularyzacyjne',
            'Udar mózgu lub TIA (przemijajace niedokrwienie mózgu)',
            'Choroba tętnic obwodowych',
            'Istotne blaszki miażdżycowe uwidocznione w badaniach obrazowych naczyń']},
    diabetes: {en: 'Diabetes', pl:'Cukrzyca'},
    diabetesHowLong: {en: 'For how long?', pl:'Jak długo?'},
    diabetesComplications: {en: 'With organ damage?', 
        pl:'Z uszkodzeniem organów?'},
    download: {en: 'Download', pl:'Pobierz'}, 
    doYouHave: {en: 'Do you have any of the following:', pl: 'Czy masz któreś z poniższych:'},
    factorsHighHeader:{en:'High risk factors:', pl:'Czynniki wysokiego ryzyka:'},
    factorsVHighHeader:{en:'Very high risk factors:', pl:'Czynniki bardzo wysokiego ryzyka:'},
    female:{en: 'Female', pl:'Kobieta'},
    fh:{en:'Family hypercholesterolemia', pl:'Hipercholesterolemia rodzinna'},
    follow: {en: 'Follow us', pl: 'Znajdź nas'},
    gender: {en:'Gender', pl: 'Płeć'},
    highRisk:{en:'High risk', pl:'Wysokiego ryzyka'},
    home: { en: 'Home', pl: 'Strona główna' },
    input: {en: 'Input', pl: 'Dane wejściowe'},
    kidneys:{ en:'Chronic kidney disease', pl:'Przewlekła choroba nerek'},
    kidnwysWhich:{en:'Which is your case?', pl:'Która z nich Ciebie dotyczy?'},
    kidneysMild:{en:'Mild ', pl: 'Łagodna '},
    kidneysModerate:{en:'Moderate ', pl: 'Umiarkowana '},
    kidneysSevere:{en:'Severe ', pl: 'Ciężka '},
    label: {en: 'SCORE profile', pl: 'Profil SCORE'},
    labels: {en: ['Low', 'Moderate', 'High', 'Very high'], pl: ['Niskie','Umiarkowane', 'Duże', 'Bardzo duże']},
    legal: {en: 'Legal', pl: 'Dokumenty prawne'},
    links: {en: 'Links', pl: 'Linki'},
    lowRisk:{en:'Low risk', pl:'Niskiego ryzyka'},
    male:{en: 'Male', pl:'Mężczyzna'},
    mobileApp: {en: 'Mobile App', pl: 'Aplikacja Mobilna'},
    no: {en: 'No', pl: 'Nie'},
    poland: {en: 'Poland', pl: "Polska"},
    pressure: {en:'Systolic BP', pl: 'Ciśnienie skurczowe'},
    pressureDia: {en:'Diastolic BP', pl: 'Ciśnienie rozkurczowe'},
    privacy: { en: 'App privacy policy', pl: 'Polityka prywatności aplikacji' },
    results: {en: 'Results', pl:'Wyniki'},
    risk: {en: 'Total CV risk: ', pl: 'Całkowite ryzyko SN: '}, 
    riskAge: {en: 'Heart age:', pl: 'Wiek serca:'},
    save: {en: 'Save risk report', pl: 'Zapisz raport ryzyka'}, 
    score: {en: 'SCORE: ', pl:'SCORE: '},
    scoreCurrent:{en: 'Current SCORE', pl: 'Obecny SCORE'},
    scoreCV: {en: 'Risk of CV disease: ', pl:'Ryzyko chorób SN: '},
    scoreHeader: {en: 'Results explanation', pl:'Objaśnienie wyników'},
    scoreInfoList: {
        en: ['SCORE (Systematic Coronary Risk Estimation) level corresponds to risk of fatal cardiovascular disease within 10 years.',
            'Risk of CV disease (fatal + non-fatal) within 10 years equals SCORE level multiplied by 3 for men and 4 for women.',
            'Heart age (also called risk age) is an age when healthy person without risk factors should reach calculated SCORE risk level.',
            'If the total CV risk is high (corresponding to SCORE >= 5%) or very high (corresponding to SCORE >= 10%) and you are not treated yet you should consult with your doctor if treatment is needed.',
            'LDL cholesterol target is determined by the level of total CV risk.',
            'All levels calculated based on European Society of Cardiology 2019 guidelines.'],
        pl: ['Wynik SCORE (Systematic Coronary Risk Estimation - Systematyczne szacowanie ryzyka wieńcowego) oznacza ryzyko wystąpienia śmiertelnej choroby sercowo-naczyniowej w ciągu najbliższych 10 lat.',
            'Ryzyko wystąpienia choroby sercowo-naczyniowej (niekoniecznie śmiertelnej) oblicza się mnożąc wynik SCORE razy 3 u mężczyzn i razy 4 u kobiet.',
            'Wiek serca (lub wiek ryzyka) to wiek w którym osoba zdrowa bez czynników ryzyka powinna osiągnąć wynik SCORE na poziomie wyliczonego.',
            'Jeżeli ryzyko całkowite jest wysokie (odpowiadające SCORE >= 5%) lub bardzo wysokie (odpowiadające SCORE >= 10%) i jeszcze nie leczysz się powinieneś się skonsultować z lekarzem w celu rozważenia terapii.',
            'Docelowy poziom cholesterolu LDL jest zdeterminowany przez poziom całkowitego ryzyka SN.',
            'Wszystkie wyniki według wytycznych Europejskiego Towarzystwa Kardiologicznego z 2019 roku.']}, 
    scoreNot: {en:' SCORE not applicable', pl: ' SCORE nie stosuje się'}, 
    scoreNotInfo: {en:'SCORE not applicable due to high risk factors. Use total CV risk instead.',
        pl: 'Wynik SCORE nie stosuje się z uwagi na czynniki wysokiego ryzyka. Zamiast niego kieruj się całkowitym ryzykiem SN.'},
    scoreResult:{en:'Risk level based on SCORE.', pl:'Poziom ryzyka na podstawie SCORE.'},
    smoking:{en: 'Smoking?', pl:'Palisz?'},
    target:{en: 'LDL cholesterol target: ', pl: 'Docelowy cholesterol LDL: '},
    targetGuidelines:{en:'Target according to European Society of Cardiology 2019 guidelines.', 
        pl:'Cel według wytycznych Europejskiego Towarzystwa Kardiologicznego z 2019 roku.'},
    targetLevelsMg:{en: ['<116 mg/dl', '<100 mg/dl', '<70 mg/dl or >=50% reduction','<55 mg/dl or >=50% reduction'],
        pl: ['<116 mg/dl', '<100 mg/dl', '<70 mg/dl lub redukcja o >=50%','<55 mg/dl lub redukcja o >=50%']},
    targetLevelsMmol:{en: ['<3 mmol/l', '<2,6 mmol/l', '<1,8 mmol/l or >=50% reduction','<1,4 mmol/l or >=50% reduction'],
        pl: ['<3 mmol/l', '<2,6 mmol/l', '<1,8 mmol/l lub redukcja o >=50%','<1,4 mmol/l lub redukcja o >=50% ']},
    terms: { en: 'App terms and conditions', pl: 'Regulamin korzystania z aplikacji' },
    years: {en: ' years', pl:' lat'},
    yes: {en: 'Yes', pl:'Tak'},
}

type Translation ={
    en:string
    pl:string
}

type GraphDescription = {
    header: Translation
    paragraph: Translation[]
}[]

export const graphDescription: GraphDescription = [
    {
        header: { en: 'Smart heart disease prevention', pl: 'Inteligentna prewencja chorób sercowo-naczyniowych' },
        paragraph: [
            { en: 'Heart diseases are number one early death cause in Europe and worldwide', pl: 'Choroby sercowo-naczyniowe stanowią główną przyczynę przedwczesnej śmierci w Europie i na świecie' },
            { en: 'Many people are unaware they are at risk', pl: 'Wiele osób jest nieświadomych tego, że znajdują się w grupie ryzyka' },
            { en: 'If your risk calculated above is high or very high you might be one of them', pl: 'Jeżeli Twoje ryzyko obliczne powyżej jest wysokie lub bardzo wysokie możesz być jedną z nich' },
            { en: 'AlgoCardio mobile app is here to help you', pl: 'Aplikacja mobilna AlgoCardio powstała po to by Ci pomóc' },
        ]
    }, {
        header: { en: "Tracking blood pressure", pl: 'Kontrola ciśnienia'},
        paragraph: [
            { en: 'Simple, intuitive graphic interface', pl: 'Prosty, intuicyjny interfejs' },
            { en: 'Easy to read for everybody', pl: 'Łatwo czytelny dla każdego' },
            { en: 'Wide range of colors reflecting blood pressure levels', pl: 'Szeroka gama kolorów odzwierciedlająca poziom ciśnienia' }
        ]
    }, {
        header: { en: 'Effective therapy', pl: 'Efektywna terapia' },
        paragraph: [
            { en: 'Therapies are sometimes ineffective due to the lack of compliance', pl: 'Terapie bywają nieskuteczne ze względu na nieprzyjmowanie zapisanych leków' },
            { en: 'Medication schedule will help you keep track of your therapy', pl: 'Harmonogram leków pomoże Ci kontrolować przebieg terapii' },
            { en: 'Thanks to notifications you will never forget to take medication', pl: 'Dzięki przypomnieniom nigdy nie zapomnisz o przyjmowaniu leków' },
        ],
    }, {
        header: { en: 'History illustrated', pl: 'Historia zilustrowana' },
        paragraph: [
            { en: 'Graphs will give you clear idea if you are at risk of hypertension', pl: 'Wykresy jasno wskażą, czy jesteś zagrożony nadciśnieniem' },
            { en: 'Help your doctor make infomed diagnosis by showing them your history', pl: 'Wspomóż swojego lekarza w diagnozie pokazując swoją historię' },
            { en: 'You can track the effects of your therapy', pl: 'Możesz śledzić skuteczność swojej terapii' }
        ],
    }]

type ShortDescription = {
    header: Translation
    paragraph: Translation
}[]
    
export const shortDescription: ShortDescription = [{
        header: { en: 'Data protection', pl: 'Ochrona danych' },
        paragraph: {
            en: 'We are not collecting, storing, processing or analysing any of the User input data from the Mobile Application. The data that you input while using the Mobile Application AlgoCardio is stored solely in the memory of your mobile device.',
            pl: 'Nie gromadzimy, nie przechowujemy, nie przetwarzamy ani nie analizujemy żadnych danych wprowadzonych przez Użytkownika do aplikacji mobilnej. Dane wprowadzane podczas korzystania z aplikacji mobilnej AlgoCardio są przechowywane wyłącznie w pamięci urządzenia mobilnego.'
        }
    }, {
        header: { en: "It's free!", pl: 'Za darmo!' },
        paragraph: { en: 'AlgoCardio is free of charge, we are also not monetizing data anyhow.', pl: 'AlgoCardio jest bezpłatne, nie zarabiamy również w żaden sposób na przetwarzaniu danych.' }

    }, {
        header: { en: 'Language support', pl: 'Dostępne języki' },
        paragraph: { en: 'AlgoCardio is available in English and Polish.', pl: 'AlgoCardio jest dostępne po polsku i po angielsku' }
    }, {
        header: { en: 'Enhanced settings', pl: 'Ustawienia zaawansowane' },
        paragraph: { en: 'You can choose different date ranges, date windows, calculate daily and weekly averages.', pl: 'Możesz wybrać różny zakres dat, przedziały czasowe, liczyć średnie dzienne i tygodniowe.' }
    }, {
        header: { en: 'Our goal', pl: 'Nasz cel' },
        paragraph: { en: 'We aim to deliver software that efficiently supports heart disease prevention and treatement while ensuring safety and privacy of its users.', 
        pl: 'Chcemy dosstarczać oprogramowanie, które efektywnie wspiera prewencję i leczenie chorób sercowo-naczyniowych przy jednoczesnym zagwarantowaniu bezpieczeństwa i prywatności użytkowników.' }
    }, {
        header: { en: 'About us', pl: 'O nas' },
        paragraph: {
            en: 'We are AlgoCardio Sp. z o.o., located in Poland. We deliver web and mobile solutions, concentrating on healthcare. If you would like to start collaboration, ask questions or share feedback regarding our products, feel free to contact us at: admin@algocardio.pl',
            pl: 'Nazywamy się AlgoCardio Sp. z o.o.. Opracowujemy rozwiązania webowe i mobilne, głównie w obszarze ochrony zdrowia. Jeśli chcecie Państwo nawiązać z nami współpracę, zadać pytania lub podzielić się opinią  odnośnie naszych produktów, zapraszamy do kontaktu na: admin@algocardio.pl'
        }
    }
]

export const privacy = [
    {
        header:
        {
            en: '',
            pl: ''
        },
        paragraph:
        {
            en:
                ' This privacy policy ("Policy") describes how Mobile Application Developer ("Mobile Application Developer", "we", "us" or "our") collects, protects and uses the personally identifiable information ("Personal Information") you ("User", "you" or "your") may provide in the AlgoCardio mobile application and any of its products or services (collectively, "Mobile Application" or "Services"). It also describes the choices available to you regarding our use of your Personal Information and how you can access and update this information. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage. ',
            pl:
                ' Niniejsza polityka prywatności („Polityka”) opisuje, w jaki sposób AlgoCardio Sp. z o.o. („programista aplikacji mobilnych”, „my”, „nas” lub „nasz”) gromadzi, chroni i wykorzystuje dane osobowe („dane osobowe”), które użytkownik („ Użytkownik ”,„ Ty ”lub„ Twój ”) może udostępniać w aplikacji mobilnej AlgoCardio i dowolnych jej produktach lub usługach (łącznie„ Aplikacja mobilna ”lub„ Usługi ”). Opisuje również dostępne opcje dotyczące korzystania z danych osobowych użytkownika oraz sposoby uzyskiwania dostępu do tych informacji i ich aktualizacji. Niniejsza Polityka nie ma zastosowania do praktyk firm, których nie jesteśmy właścicielami ani nie kontrolujemy, ani do osób, których nie zatrudniamy ani nie zarządzamy.  '
        }
    },
    {
        header:
        {
            en: ' Automatic collection of information ',
            pl: 'Automatyczne zbieranie informacji  '
        },
        paragraph:
        {
            en:
                '  When you open the Mobile Application our servers automatically record information that your device sends. This data may include information such as your device\'s IP address and location, device name and version, operating system type and version, language preferences, information you search for in our Mobile Application, access times and dates, and other statistics. ',
            pl:
                ' Po otwarciu aplikacji mobilnej nasze serwery automatycznie rejestrują informacje wysyłane przez urządzenie. Dane te mogą obejmować takie informacje, jak adres IP i lokalizacja urządzenia, nazwa i wersja urządzenia, typ i wersja systemu operacyjnego, preferencje językowe, informacje wyszukiwane w naszej aplikacji mobilnej, godziny i daty dostępu oraz inne statystyki.   '
        }
    },
    {
        header: { en: ' Medical Information ', pl: ' Dane medyczne ' },
        paragraph:
        {
            en:
                '  We are not collecting, storing, processing or analysing any of the User input data from the Mobile Application. The data that you input while using the Mobile Application AlgoCardio is stored solely in the memory of your mobile device. It is your sole responsibility to protect your data and share it responsibly. In no event shall we be held liable for any breaches in personal data caused by inappropriate use of the Mobile Application. ',
            pl:
                ' Nie gromadzimy, nie przechowujemy, nie przetwarzamy ani nie analizujemy żadnych danych wprowadzonych przez Użytkownika do aplikacji mobilnej. Dane wprowadzane podczas korzystania z aplikacji mobilnej AlgoCardio są przechowywane wyłącznie w pamięci urządzenia mobilnego. Twoim obowiązkiem jest ochrona danych i odpowiedzialne udostępnianie. W żadnym wypadku nie będziemy ponosić odpowiedzialności za jakiekolwiek naruszenia danych osobowych spowodowane niewłaściwym użyciem aplikacji mobilnej.   '
        }
    },
    {
        header:
        {
            en: ' Collection of personal information ',
            pl: 'Zbieranie danych osobowych  '
        },
        paragraph:
        {
            en:
                '  You can visit the Mobile Application without telling us who you are or revealing any information by which someone could identify you as a specific, identifiable individual. If, however, you wish to use some of the Mobile Application\'s features, you will be asked to provide certain Personal Information (for example, your name and e-mail address). We receive and store any information you knowingly provide to us when you send us an email.   ',
            pl:
                ' Możesz korzystać z aplikacji mobilnej, nie ujawniając nam, kim jesteś ani nie ujawniając żadnych informacji, za pomocą których ktoś mógłby zidentyfikować Cię jako konkretną, możliwą do zidentyfikowania osobę. Jeśli jednak chcesz korzystać z niektórych funkcji aplikacji mobilnej, zostaniesz poproszony o podanie niektórych danych osobowych (na przykład imienia i nazwiska oraz adresu e-mail). Otrzymujemy i przechowujemy wszelkie informacje, które świadomie nam przekazujesz, wysyłając nam wiadomość e-mail.  '
        }
    },
    {
        header:
        {
            en: 'Use and processing of collected information  ',
            pl: 'Wykorzystywanie i przetwarzanie zebranych informacji  '
        },
        paragraph:
        {
            en:
                ' Any of the information we collect from you may be used to personalize your experience; improve our Mobile Application; improve customer service and respond to queries and emails of our customers; run and operate our Mobile Application and Services. Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding Mobile Application traffic and usage. This statistical information is not otherwise aggregated in such a way that would identify any particular user of the system.  We may process Personal Information related to you if one of the following applies: (i) You have given your consent for one or more specific purposes. Note that under some legislations we may be allowed to process information until you object to such processing (by opting out), without having to rely on consent or any other of the following legal bases below. This, however, does not apply, whenever the processing of Personal Information is subject to European data protection law; (ii) Provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof; (iii) Processing is necessary for compliance with a legal obligation to which you are subject; (iv) Processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; (v) Processing is necessary for the purposes of the legitimate interests pursued by us or by a third party. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.   ',
            pl:
                ' Wszelkie informacje, które od Ciebie zbieramy, mogą zostać wykorzystane do spersonalizowania Twojego doświadczenia; ulepszenia naszej aplikacji mobilnej; poprawienia obsługi klienta i odpowiadania na zapytania i e-maile naszych klientów; uruchamienia i obsługi naszej aplikacji mobilnej i usług. Informacje zbierane automatycznie są wykorzystywane wyłącznie do identyfikowania potencjalnych przypadków nadużyć i ustalenia informacji statystycznych dotyczących ruchu i użytkowania aplikacji mobilnych. Te informacje statystyczne nie są w agregowane w sposób umożliwiający identyfikację konkretnego użytkownika systemu.  Możemy przetwarzać związane z Tobą dane osobowe, jeśli spełniony jest jeden z poniższych warunków: (i) wyraziłeś zgodę na co najmniej jeden konkretny cel. Pamiętaj, że zgodnie z niektórymi przepisami możemy przetwarzać informacje, dopóki nie sprzeciwisz się takiemu przetwarzaniu (poprzez rezygnację), bez konieczności polegania na zgodzie lub innej z poniższych podstaw prawnych. Nie dotyczy to jednak przypadków, w których przetwarzanie danych osobowych podlega europejskiemu prawu ochrony danych; (ii) Dostarczenie informacji jest konieczne do wykonania umowy z tobą i / lub do wszelkich zobowiązań przedumownych; (iii) Przetwarzanie jest konieczne do spełnienia zobowiązania prawnego, któremu podlega; (iv) Przetwarzanie jest związane z zadaniem realizowanym w interesie publicznym lub w ramach powierzonego nam sprawowania władzy publicznej; (v) Przetwarzanie jest konieczne do celów uzasadnionych interesów realizowanych przez nas lub przez stronę trzecią. W każdym razie z przyjemnością wyjaśnimy konkretną podstawę prawną, która ma zastosowanie do przetwarzania, aw szczególności to, czy podanie Danych osobowych jest wymogiem ustawowym lub umownym, czy też wymogiem koniecznym do zawarcia umowy.   '
        }
    },
    {
        header:
        {
            en: 'Information transfer and storage  ',
            pl: 'Przesyłanie i przechowywanie informacji  '
        },
        paragraph:
        {
            en:
                ' Depending on your location, data transfers may involve transferring and storing your information in a country other than your own. You are entitled to learn about the legal basis of information transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by us to safeguard your information. If any such transfer takes place, you can find out more by checking the relevant sections of this document or inquire with us using the information provided in the contact section.   ',
            pl:
                ' W zależności od lokalizacji przesyłanie danych może obejmować przesyłanie i przechowywanie informacji w kraju innym niż twój. Masz prawo dowiedzieć się o podstawie prawnej przekazywania informacji do kraju spoza Unii Europejskiej lub jakiejkolwiek organizacji międzynarodowej podlegającej prawu międzynarodowemu publicznemu lub utworzonej przez co najmniej dwa kraje, np. ONZ, oraz o środkach bezpieczeństwa podjętych przez nas w celu ochrony twoich informacji. Jeśli nastąpi takie przeniesienie, możesz dowiedzieć się więcej, sprawdzając odpowiednie sekcje tego dokumentu lub pytając nas, korzystając z informacji podanych w sekcji kontakt.   '
        }
    },
    {
        header: { en: 'The rights of users  ', pl: 'Prawa użytkowników  ' },
        paragraph:
        {
            en:
                ' You may exercise certain rights regarding your information processed by us. In particular, you have the right to do the following: (i) you have the right to withdraw consent where you have previously given your consent to the processing of your information; (ii) you have the right to object to the processing of your information if the processing is carried out on a legal basis other than consent; (iii) you have the right to learn if information is being processed by us, obtain disclosure regarding certain aspects of the processing and obtain a copy of the information undergoing processing; (iv) you have the right to verify the accuracy of your information and ask for it to be updated or corrected; (v) you have the right, under certain circumstances, to restrict the processing of your information, in which case, we will not process your information for any purpose other than storing it; (vi) you have the right, under certain circumstances, to obtain the erasure of your Personal Information from us; (vii) you have the right to receive your information in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that your information is processed by automated means and that the processing is based on your consent, on a contract which you are part of or on pre-contractual obligations thereof.   ',
            pl:
                ' Możesz skorzystać z niektórych praw dotyczących przetwarzanych przez nas informacji. W szczególności masz prawo wykonać następujące czynności: (i) masz prawo wycofać zgodę, jeżeli wcześniej wyraziłeś zgodę na przetwarzanie twoich informacji; (ii) masz prawo sprzeciwić się przetwarzaniu twoich informacji, jeżeli przetwarzanie odbywa się na podstawie prawnej innej niż zgoda; (iii) masz prawo dowiedzieć się, czy informacje są przetwarzane przez nas, uzyskać informacje dotyczące niektórych aspektów przetwarzania i uzyskać kopię informacji poddawanych przetwarzaniu; (iv) masz prawo zweryfikować dokładność swoich informacji i poprosić o ich aktualizację lub poprawienie; (v) masz prawo, w pewnych okolicznościach, ograniczyć przetwarzanie swoich informacji, w takim przypadku nie będziemy przetwarzać twoich informacji w żadnym innym celu niż ich przechowywanie; (vi) masz prawo, w pewnych okolicznościach, do usunięcia od nas danych osobowych; (vii) masz prawo do otrzymywania informacji w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego oraz, jeśli jest to technicznie wykonalne, do przekazania ich innemu administratorowi bez żadnych przeszkód. To postanowienie ma zastosowanie pod warunkiem, że Twoje dane są przetwarzane w sposób zautomatyzowany, a przetwarzanie opiera się na Twojej zgodzie, na umowie, której jesteś częścią, lub na jej zobowiązaniach przedumownych.   '
        }
    },
    {
        header:
        {
            en: 'The right to object to processing  ',
            pl: 'Prawo do sprzeciwu wobec przetwarzania  '
        },
        paragraph:
        {
            en:
                ' Where Personal Information is processed for the public interest, in the exercise of an official authority vested in us or for the purposes of the legitimate interests pursued by us, you may object to such processing by providing a ground related to your particular situation to justify the objection. You must know that, however, should your Personal Information be processed for direct marketing purposes, you can object to that processing at any time without providing any justification. To learn, whether we are processing Personal Information for direct marketing purposes, you may refer to the relevant sections of this document.   ',
            pl:
                ' W przypadku przetwarzania danych osobowych w interesie publicznym, w ramach sprawowania władzy publicznej powierzonego nam lub w celu realizacji uzasadnionych interesów realizowanych przez nas, możesz sprzeciwić się takiemu przetwarzaniu, podając uzasadnienie związane z twoją szczególną sytuacją w celu uzasadnienia sprzeciw. Musisz jednak wiedzieć, że jeśli Twoje Dane osobowe będą przetwarzane w celach marketingu bezpośredniego, możesz sprzeciwić się takiemu przetwarzaniu w dowolnym momencie bez podania uzasadnienia. Aby dowiedzieć się, czy przetwarzamy Dane osobowe w celach marketingu bezpośredniego, możesz zapoznać się z odpowiednimi sekcjami tego dokumentu.   '
        }
    },
    {
        header:
        {
            en: 'How to exercise these rights  ',
            pl: 'Jak korzystać z tych praw  '
        },
        paragraph:
        {
            en:
                ' Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible.   ',
            pl:
                ' Wszelkie prośby o skorzystanie z praw użytkownika można kierować do właściciela za pośrednictwem danych kontaktowych podanych w tym dokumencie. Prośby te mogą być wykonane bezpłatnie i zostaną rozpatrzone przez Właściciela jak najwcześniej.   '
        }
    },
    {
        header: { en: 'Privacy of children  ', pl: ' Prywatność dzieci ' },
        paragraph:
        {
            en:
                ' We do not knowingly collect any Personal Information from children under the age of 13. If you are under the age of 13, please do not submit any Personal Information through our Mobile Application or Service. We encourage parents and legal guardians to monitor their children\'s Internet usage and to help enforce this Policy by instructing their children never to provide Personal Information through our Mobile Application or Service without their permission. If you have reason to believe that a child under the age of 13 has provided Personal Information to us through our Mobile Application or Service, please contact us. You must also be at least 16 years of age to consent to the processing of your Personal Information in your country (in some countries we may allow your parent or guardian to do so on your behalf).   ',
            pl:
                ' Nie zbieramy świadomie żadnych danych osobowych od dzieci w wieku poniżej 13 lat. Jeśli masz poniżej 13 lat, nie przesyłaj żadnych danych osobowych za pośrednictwem naszej aplikacji mobilnej lub usługi. Zachęcamy rodziców i opiekunów prawnych do monitorowania korzystania z Internetu przez ich dzieci i do pomocy w egzekwowaniu niniejszej Polityki, instruując ich, aby nigdy nie podawały Danych osobowych za pośrednictwem naszej aplikacji mobilnej lub Usługi bez ich zgody. Jeśli masz powody, by sądzić, że dziecko w wieku poniżej 13 lat przekazało nam dane osobowe za pośrednictwem naszej aplikacji mobilnej lub usługi, skontaktuj się z nami. Musisz również mieć co najmniej 16 lat, aby wyrazić zgodę na przetwarzanie Twoich danych osobowych w twoim kraju (w niektórych krajach możemy zezwolić twojemu rodzicowi lub opiekunowi na to w Twoim imieniu).   '
        }
    },
    {
        header:
        {
            en: 'Links to other mobile applications  ',
            pl: 'Linki do innych aplikacji mobilnych  '
        },
        paragraph:
        {
            en:
                ' Our Mobile Application contains links to other mobile applications that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other mobile applications or third-parties. We encourage you to be aware when you leave our Mobile Application and to read the privacy statements of each and every mobile application that may collect Personal Information.   ',
            pl:
                ' Nasza aplikacja mobilna zawiera łącza do innych aplikacji mobilnych, które nie są naszą własnością ani nie są przez nas kontrolowane. Należy pamiętać, że nie ponosimy odpowiedzialności za praktyki dotyczące prywatności takich innych aplikacji mobilnych lub stron trzecich. Zachęcamy do zachowania ostrożności podczas opuszczania naszej aplikacji mobilnej i do zapoznania się z oświadczeniami o ochronie prywatności każdej aplikacji mobilnej, która może gromadzić dane osobowe.   '
        }
    },
    {
        header:
        {
            en: 'Information security  ',
            pl: 'Bezpieczeństwo informacji  '
        },
        paragraph:
        {
            en:
                ' We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal Information in its control and custody. However, no data transmission over the Internet or wireless network can be guaranteed. Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there are security and privacy limitations of the Internet which are beyond our control; (ii) the security, integrity, and privacy of any and all information and data exchanged between you and our Mobile Application cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in transit by a third-party, despite best efforts.   ',
            pl:
                ' Zabezpieczamy informacje podawane na serwerach komputerowych w kontrolowanym, bezpiecznym środowisku, chronione przed nieuprawnionym dostępem, wykorzystaniem lub ujawnieniem. Utrzymujemy odpowiednie zabezpieczenia administracyjne, techniczne i fizyczne w celu ochrony przed nieautoryzowanym dostępem, wykorzystaniem, modyfikacją i ujawnieniem danych osobowych znajdujących się pod jego kontrolą i nadzorem. Nie można jednak zagwarantować transmisji danych przez Internet lub sieć bezprzewodową. Dlatego, chociaż staramy się chronić Twoje Dane osobowe, potwierdzasz, że (i) istnieją ograniczenia bezpieczeństwa i prywatności w Internecie, które są poza naszą kontrolą; (ii) bezpieczeństwo, integralność i prywatność wszelkich informacji i danych wymienianych między Tobą a naszą aplikacją mobilną nie może być zagwarantowana; oraz (iii) pomimo wszelkich starań wszelkie takie informacje i dane mogą być przeglądane lub manipulowane w tranzycie przez osobę trzecią.   '
        }
    },
    {
        header: { en: 'Data breach  ', pl: 'Naruszenie danych  ' },
        paragraph:
        {
            en:
                ' In the event we become aware that the security of the Mobile Application has been compromised or users Personal Information has been disclosed to unrelated third parties as a result of external activity, including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities. In the event of a data breach, we will make reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the user as a result of the breach or if notice is otherwise required by law. When we do, we will post a notice in the Mobile Application.   ',
            pl:
                ' W przypadku, gdy dowiemy się, że bezpieczeństwo aplikacji mobilnej zostało naruszone lub dane osobowe użytkowników zostały ujawnione niepowiązanym stronom trzecim w wyniku działań zewnętrznych, w tym między innymi ataków bezpieczeństwa lub oszustwa, zastrzegamy sobie prawo podejmować racjonalnie odpowiednie środki, w tym między innymi dochodzenie i sprawozdawczość, a także powiadamianie organów ścigania i współpracę z nimi. W przypadku naruszenia ochrony danych dołożymy uzasadnionych starań, aby powiadomić osoby, których to dotyczy, jeśli uznamy, że istnieje uzasadnione ryzyko szkody dla użytkownika w wyniku naruszenia lub jeśli zawiadomienie jest wymagane przez prawo. Kiedy to zrobimy, opublikujemy powiadomienie w aplikacji mobilnej.   '
        }
    },
    {
        header: { en: 'Legal disclosure  ', pl: 'Informacje prawne  ' },
        paragraph:
        {
            en:
                ' We will disclose any information we collect, use or receive if required or permitted by law, such as to comply with a subpoena, or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.   ',
            pl:
                ' Ujawnimy wszelkie informacje, które zbieramy, wykorzystujemy lub otrzymujemy, jeśli jest to wymagane lub dozwolone przez prawo, takie jak postępowanie sądowe lub podobny proces prawny, a gdy w dobrej wierze wierzymy, że ujawnienie jest konieczne do ochrony naszych praw, ochrony Twojego bezpieczeństwa lub bezpieczeństwa innych osób, badania oszustwa lub odpowiedzi na żądanie rządu.   '
        }
    },
    {
        header:
            { en: 'Changes and amendments  ', pl: 'Zmiany i poprawki  ' },
        paragraph:
        {
            en:
                ' We may update this Privacy Policy from time to time in our discretion and will notify you of any material changes to the way in which we treat Personal Information. When changes are made, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways in our discretion, such as through contact information you have provided. Any updated version of this Privacy Policy will be effective immediately upon the posting of the revised Privacy Policy unless otherwise specified. Your continued use of the Mobile Application or Services after the effective date of the revised Privacy Policy (or such other act specified at that time) will constitute your consent to those changes. However, we will not, without your consent, use your Personal Data in a manner materially different than what was stated at the time your Personal Data was collected. Policy was created with WebsitePolicies.   ',
            pl:
                ' Możemy od czasu do czasu aktualizować niniejszą Politykę prywatności według własnego uznania i powiadomimy Cię o wszelkich istotnych zmianach w sposobie, w jaki traktujemy Dane osobowe. Po wprowadzeniu zmian zmienimy zaktualizowaną datę na dole tej strony. Możemy również powiadomić Cię w inny sposób według naszego uznania, na przykład poprzez podane przez Ciebie dane kontaktowe. Każda zaktualizowana wersja niniejszej Polityki prywatności wejdzie w życie natychmiast po opublikowaniu zmienionej Polityki prywatności, chyba że określono inaczej. Dalsze korzystanie z aplikacji mobilnej lub usług po wejściu w życie zmienionej Polityki prywatności (lub innego określonego w tym czasie aktu) będzie stanowić zgodę na te zmiany. Nie będziemy jednak, bez Twojej zgody, wykorzystywać Twoich danych osobowych w sposób istotnie inny niż ten, który został podany w momencie gromadzenia danych osobowych. Polityka została utworzona za pomocą WebsitePolicies.   '
        }
    },
    {
        header:
        {
            en: 'Acceptance of this policy  ',
            pl: 'Akceptacja tej polityki  '
        },
        paragraph:
        {
            en:
                ' You acknowledge that you have read this Policy and agree to all its terms and conditions. By using the Mobile Application or its Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to use or access the Mobile Application and its Services.   ',
            pl:
                ' Użytkownik przyjmuje do wiadomości, że zapoznał się z niniejszą Polityką i wyraża zgodę na wszystkie jej warunki. Korzystając z aplikacji mobilnej lub jej usług, wyrażasz zgodę na przestrzeganie niniejszych zasad. Jeśli nie wyrażasz zgody na przestrzeganie warunków niniejszej Polityki, nie jesteś upoważniony do używania ani uzyskiwania dostępu do Aplikacji mobilnej i jej Usług.   '
        }
    },
    {
        header: { en: 'Contacting us  ', pl: 'Kontakt z nami  ' },
        paragraph:
        {
            en:
                ' If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may send an email to admin@algocardio.pl',
            pl:
                ' Jeśli chcesz się z nami skontaktować, aby dowiedzieć się więcej na temat niniejszej Polityki lub chcesz się z nami skontaktować w sprawie wszelkich kwestii związanych z prawami osobistymi i Twoimi danymi osobowymi, możesz wysłać wiadomość e-mail na adres admin@algocardio.pl '
        }
    }, {
        header: { en: '', pl: '' },
        paragraph:
        {
            en:
                ' This document was last updated on September 10, 2019 ',
            pl:
                ' Ten dokument został ostatnio zaktualizowany 10 września 2019 r '
        }
    }]

export const terms = [{
    header:
    {
        en: '',
        pl: ''
    },
    paragraph:
    {
        en:
            'These terms and conditions ("Terms", "Agreement") are an agreement between AlgoCardio Sp. z o.o. ("AlgoCardio Sp. z o.o.", "us", "we" or "our") and you ("User", "you" or "your"). This Agreement sets forth the general terms and conditions of your use of the AlgoCardio mobile application and any of its products or services (collectively, "Mobile Application" or "Services").   ',
        pl:
            'Niniejsze warunki („Warunki”, „Umowa”) są umową między AlgoCardio Sp. z o.o. („Programista aplikacji mobilnej”, „nas”, „my” lub „nasz”) a użytkownikiem („Użytkownik”, „użytkownik” lub "Ty"). Niniejsza Umowa określa ogólne warunki korzystania z aplikacji mobilnej AlgoCardio oraz wszelkich jej produktów lub usług (łącznie „Aplikacja mobilna” lub „Usługi”).   '
    }
},
{
    header:
    {
        en: '    Using AlgoCardio ',
        pl: '    Korzystanie z AlgoCardio '
    },
    paragraph:
    {
        en:
            ' AlgoCardio helps you document history of blood pressure measurements and medication intake. The content of the Mobile Application reflects current guidelines of European Society of Cardiology. The content of AlgoCardio does not constitute professional medical advice. You should rely on only on your doctor\'s advice for diagnosis and altering your medication intake. You should always consult the content of AlgoCardio with your doctor.   ',
        pl:
            ' AlgoCardio pomaga dokumentować historię pomiarów ciśnienia krwi i przyjmowania leków. Treść aplikacji mobilnej odzwierciedla aktualne wytyczne Europejskiego Towarzystwa Kardiologicznego. Zawartość AlgoCardio nie stanowi profesjonalnej porady medycznej. Powinieneś polegać wyłącznie na poradach lekarza dotyczących diagnozy i zmiany w przyjmowaniu leków. Zawsze powinieneś konsultować zawartość AlgoCardio ze swoim lekarzem.   '
    }
},
{
    header: { en: '    Backups ', pl: 'Kopie zapasowe ' },
    paragraph:
    {
        en:
            ' We are not responsible for Content residing in the Mobile Application. In no event shall we be held liable for any loss of any Content. It is your sole responsibility to maintain appropriate backup of your Content.   ',
        pl:
            ' Nie ponosimy odpowiedzialności za zawartość znajdującą się w aplikacji mobilnej. W żadnym wypadku nie ponosimy odpowiedzialności za utratę jakichkolwiek Treści. Twoim obowiązkiem jest utrzymanie odpowiedniej kopii zapasowej Treści.   '
    }
},
{
    header:
    {
        en: 'Links to other mobile applications ',
        pl: 'Linki do innych aplikacji mobilnych '
    },
    paragraph:
    {
        en:
            ' We are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked mobile application. Some of the links in the Mobile Application may be "affiliate links". We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their mobile applications. We do not assume any responsibility or liability for the actions, products, services, and content of any other third-parties. You should carefully review the legal statements and other conditions of use of any mobile application or website which you access through a link from this Mobile Application. Your linking to any other off-site mobile applications is at your own risk.   ',
        pl:
            ' Nie sugerujemy, bezpośrednio ani pośrednio, żadnej zgody, stowarzyszenia, sponsorowania, poparcia lub powiązania z jakąkolwiek aplikacją mobilną. Niektóre łącza w aplikacji mobilnej mogą być „linkami partnerskimi”. Nie ponosimy odpowiedzialności za badanie lub ocenę, a także nie gwarantujemy ofert firm, osób prywatnych ani treści ich aplikacji mobilnych. Nie ponosimy żadnej odpowiedzialności za działania, produkty, usługi i treści innych stron trzecich. Powinieneś dokładnie zapoznać się z oświadczeniami prawnymi i innymi warunkami korzystania z dowolnej aplikacji mobilnej i stron internetowych, do których uzyskasz dostęp poprzez link z tej aplikacji mobilnej. Twoje linkowanie do innych aplikacji mobilnych poza serwisem odbywa się na własne ryzyko.   '
    }
},
{
    header: { en: 'Changes and amendments ', pl: 'Zmiany i poprawki ' },
    paragraph:
    {
        en:
            ' We reserve the right to modify this Agreement or its policies relating to the Mobile Application or Services at any time, effective upon posting of an updated version of this Agreement in the Mobile Application. When we do, we will revise the updated date at the bottom of this page. Continued use of the Mobile Application after any such changes shall constitute your consent to such changes. Policy was created with WebsitePolicies.   ',
        pl:
            ' Zastrzegamy sobie prawo do zmiany niniejszej Umowy lub jej zasad dotyczących Aplikacji mobilnej lub Usług w dowolnym momencie, ze skutkiem po opublikowaniu zaktualizowanej wersji niniejszej Umowy w Aplikacji mobilnej. Kiedy to zrobimy, zaktualizujemy datę na dole tej strony. Dalsze korzystanie z Aplikacji mobilnej po takich zmianach oznacza zgodę na takie zmiany. Polityka została stworzona za pomocą WebsitePolicies.    '
    }
},
{
    header:
    {
        en: 'Acceptance of these terms ',
        pl: 'Akceptacja tych warunków '
    },
    paragraph:
    {
        en:
            ' You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Mobile Application or its Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access the Mobile Application and its Services.   ',
        pl:
            ' Potwierdzasz, że przeczytałeś niniejszą Umowę i zgadzasz się na wszystkie jej warunki. Korzystając z Aplikacji mobilnej lub jej Usług, wyrażasz zgodę na związanie się niniejszą Umową. Jeśli nie zgadzasz się na przestrzeganie warunków niniejszej Umowy, nie jesteś upoważniony do używania lub uzyskiwania dostępu do Aplikacji mobilnej i jej Usług.   '
    }
},
{
    header: { en: 'Contacting us ', pl: 'Kontakt z nami ' },
    paragraph:
    {
        en:
            ' If you have any questions about this Agreement, please contact us. ',
        pl:
            ' Jeśli masz jakiekolwiek pytania dotyczące tej umowy, skontaktuj się z nami.'
    }
},
{
    header: { en: '', pl: '' },
    paragraph:
    {
        en:
            ' This document was last updated on September 10, 2019 ',
        pl:
            ' Ten dokument był ostatnio aktualizowany 10 września 2019 roku '
    }
}]


export const cookies = [
    {
        header: { en: '', pl: '' },
        paragraph:
        {
            en:
                ' This cookie policy is an agreement between the uer ("you") and AlgoCardio Sp. z o.o. ("us", "Algocardio.eu"). It describes the use of cookie files on Algocardio.eu website.',
            pl:
                ' Niniejsza polityka plików cookie jest umową między użytkownikiem („ty”) a AlgoCardio Sp. z o.o. („my”, „Algocardio.eu”). Opisuje wykorzystanie plików cookie na stronie Algocardio.eu. '
        }
    },{
        header: { en: 'What are cookies?', pl: 'Czym są pliki cookie?' },
        paragraph:
        {
            en:
                " Cookies are small text files saved on your computer when you are loading a website. Their purpose is to remember users and their preferences. They can either be session cookies or persistent cookies. Session cookies are deleted when you close the browser, persistent cookies stay on user's computer after closing the website. Cookies can also be classified as first-party or third-party cookies. First-party cookies are created by the website owner and third-party cookies are provided by other entities, typically for analytic or advertising purposes." ,
            pl:
                ' Pliki cookie to małe pliki tekstowe zapisywane na komputerze podczas ładowania strony internetowej. Ich celem jest zapamiętanie użytkowników i ich preferencji. Pliki cookie mogą być albo sesyjnymi, albo trwałymi. Sesyjne pliki cookie są usuwane po zamknięciu przeglądarki, pliki trwałe pozostają zapisane w komputerze użytkownika po zamknięciu strony. Pliki cookie można również zaklasyfikować jako pliki cookie własne lub zewnętrzne. Własne pliki cookie są stworzone przez właściciela witryny, a zewnętrzne są dostarczane przez strony trzecie, zwykle do celów analitycznych lub reklamowych.'
        }
    },{
        header: { en: 'What type of cookies is Algocario.eu using?', pl: 'Jakiego rodzaju pliki cookie są używane przez stronę algocardio.pl?' },
        paragraph:
        {
            en: 
                "Algocardio.eu is using analytical cookies provided by Google Analytics. These are third-party (Google), both session and persistent cookies. The purpose of using them is to better understand what type of users are using the website based on usage statistics.",
            pl:
               "Algocardio.eu wykorzystuje analityczne pliki cookie dostarczane przez Google Analytics. Są to pliki cookie strony trzeciej (Google), zarówno sesyjne, jak i trwałe. Celem ich używania jest lepsze zrozumienie, jakiego rodzaju użytkownicy korzystają ze strony internetowej na podstawie statystyk użytkowników.",
        }
    },{
        header: { en: 'What can you do about cookies?', pl: 'Co możesz zrobić z plikami cookie?' },
        paragraph:
        {
            en: 
                "Users can delete cookie files from their computer or block the use of cookies using browser settings.",
            pl:
                "Użytkownicy mogą usuwać pliki cookie ze swojego komputera lub blokować korzystanie z plików cookie za pomocą ustawień przeglądarki."
        }
    },{
        header: { en: 'Changes in cookie policy', pl: 'Zmiana polityki cookie' },
        paragraph:
        {
            en: 
                "We reserve the right to change this cookie policy. If the cookie policy changes, the effective date at the end of this document will be updated.",
            pl:
                "Zastrzegamy sobie prawo do zmiany niniejszej polityki cookie. W przypadku zmiany polityki cookie uaktualniona zostanie data jej obowiązywania znajdująca się na końcu niniejszego dokumentu."
        }
    },{
        header: { en: 'Acceptance of this cookies policy', pl: 'Akceptacja niniejszej polityki cookie' },
        paragraph:
        {
            en: 
                "By remaining on this site the user accepts this cookie policy. If you do not accept this policy, you are not entitled to continue using this site.",
            pl:
                "Użytkownik pozostając na stronie Algocardio.eu akceptuje niniejszą politykę cookie. W przypadku braku akceptacji niniejszej polityki użytkownik nie ma prawa do dalszego korzystania z tej witryny."
        }
    },{
        header: { en: 'Contacting us', pl: 'Kontakt z nami' },
        paragraph:
        {
            en: 
                "If you want to learn more about the cookie policy of Algocardio.eu, you can contact us by email at: admin@algocardio.pl",
            pl:
                "Jeżeli chcesz dowiedzieć się więcej na temat polityki cookie serwisu Algocardio.eu możesz skontaktować się z nami drogą e-mail na adres: admin@algocardio.pl"
        }
    },{
        header: { en: '', pl: '' },
        paragraph:
        {
            en: 
                "This document is valid from November 14, 2019",
            pl:
                "Ten dokument obowiązuje od 14 listopada 2019"
        }
    }
]
