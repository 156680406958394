import React from 'react';
import {
    VictoryAxis,
    VictoryChart,
    VictoryLabel,
    VictoryLine,
    VictoryScatter,
    VictoryTheme,
    VictoryVoronoiContainer
} from "victory";
import { Language } from '../App';
import '../App.css';
import { dict } from '../utils/dict';
import { SCORE, ScoreProfile, BaseUser, category, ScoreTable } from '../utils/SCORE';

export const getMiddles = (ticksArray: number[]) => {
    return ticksArray.slice(1).map((tick, i) => {
        return (ticksArray[i] + ticksArray[i + 1]) / 2
    });
};

export default function Graph(props: {lang: Language, user: ScoreProfile}) {
    const { user } = props
    let data = (SCORE as ScoreTable)
        .filter(d=>Object.keys(BaseUser).filter(k=>k !=='age').every(k=>user[k]===d[k]))
        .map(d => {
            return {
                x: d.age,
                y: d.risk,
            }
        });
    let point= (SCORE as ScoreTable)
        .filter(d=>Object.keys(BaseUser).every(k=>user[k]===d[k]))
        .map(d => {
            return {
                x: d.age,
                y: d.risk,
                point: true
            }
        });

    let dataY = data.map(record => record.y);
    let minDataY = Math.min(...dataY);
    let maxDataY = Math.max(...dataY);
    let newTicks = [minDataY].concat(category.ticks.filter(tick => minDataY < tick && tick <= maxDataY)).concat([maxDataY]);
    let newColors = category.colors.filter((color, index) => minDataY < category.ticks[index + 1] && category.ticks[index] <= maxDataY);
  
    return(
        <div>
            <VictoryChart
                maxDomain={{y: Math.max(15,maxDataY)}}
                theme={VictoryTheme.material}
                containerComponent={
                    <VictoryVoronoiContainer
                        voronoiBlacklist={['point']}
                        labels={({ datum }) => `${datum.point ? dict.scoreCurrent[props.lang]: datum.y+'%'}`}
                    />
                }
            >

                {data.length >= 2 ?
                    <defs>
                        <linearGradient id="myGradient"
                                        x1="0%" y1="0%" x2="0%" y2="100%"
                        >
                            {newColors.map((color, index) => {
                                return <stop
                                    key={color}
                                    offset={maxDataY === minDataY ? 1 : (maxDataY - ((newTicks[index] + newTicks[index + 1]) / 2)) / (maxDataY - minDataY)}
                                    stopColor={color}
                                    stopOpacity={1}
                                />
                            }).slice(0).reverse()}
                        </linearGradient>
                    </defs>
                    : null}
                
                <VictoryLine
                    interpolation="monotoneX"
                    style={{
                        data: {
                            stroke: "url(#myGradient)",
                            strokeWidth: 3
                        }
                    }}
                    animate={{duration: 1000}}
                    padding={0}
                    data={data}
                    name="line"
                />

                <VictoryScatter
                    // style={{
                    //     data:
                    //         {fill: d => getRGBColor(category, d.y)}
                    // }}
                    animate={{duration: 1000}}
                    size={5}
                    data={point}
                />
            
                <VictoryLabel
                    text={dict.label[props.lang]}
                    textAnchor="middle"
                    y={30}
                    x={180}
                />

                <VictoryAxis
                    dependentAxis
                    tickValues={[0, 1, 5, 10, 15]}
                    // standalone={false}
                    label='SCORE'
                    style={{axisLabel: {padding: 30}}}
                />

                <VictoryAxis
                    dependentAxis
                    tickValues={getMiddles([0, 1, 5, 10, 15])}
                    tickFormat={dict.labels[props.lang]}
                    // standalone={false}
                    orientation='right'
                    axisValue={40}
                />

                <VictoryAxis
                    // standalone={false}
                    tickValues={[40,50,55,60,65,70]}
                    label={dict.age[props.lang]}
                    style={{axisLabel: {padding: 30}}}
                />
            </VictoryChart>
        </div>
    )
}
