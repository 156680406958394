import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import React, { useState } from 'react';
import { Language } from '../App';
import '../App.css';
import { dict } from '../utils/dict';
import { BaseUser, Cases, FullProfile } from '../utils/SCORE';
import InfoDialog from './InfoDialog';
import Result from './Result';
import SelectControl from './SelectControl';

export default function Risk(props: {lang: Language}) {
    const [user, setUser] = useState<FullProfile>({
        ...BaseUser,
        cholUnit: 0,
        ldl: 0,
        cvEvent: false,
        diastolic: 0,
        diabetes: false, 
        diabetesDuration: 0,
        diabetesComplications: 0,
        kidneys: false,
        kidneysWhich: 0,
        fh: false
    })
    const { cholUnit, ldl, cvEvent, diastolic, diabetes, diabetesDuration, diabetesComplications, kidneys, kidneysWhich, fh } = user
    const changeGender = (a: any) =>setUser({...user, gender: a.target.value as number})
    const changeAge = (a: any) =>setUser({...user, age: a.target.value as number})
    const changeSmoking = (a: any) =>setUser({...user, smoking: a.target.value as number})
    const changeCountry = (c: any) => setUser({...user, country: c.target.value as Cases})
    const changePressure = (p: any) =>setUser({...user, pressure: p.target.value as number})
    const changeDiastolic = (p: any) =>setUser({...user, diastolic: p.target.value as number})
    const changeCholesterol = (p: any) =>setUser({...user, cholesterol: p.target.value as number})
    const changeLdl = (p: any) =>setUser({...user, ldl: p.target.value as number})
    const changeCholUnit = (p: any) =>setUser({...user, cholUnit: p.target.value as number})
    const changeDiabetesDuration = (c: any) => setUser({...user, diabetesDuration: c.target.value as Cases})
    const changeDiabetesComplications = (c: any) => setUser({...user, diabetesComplications: c.target.value as number})
    const changeKidneysWhich = (c: any) => setUser({...user, kidneysWhich: c.target.value as Cases})
    // const changeValue = (changedValue: any) => setUser({...user, ...changedValue})

    const genderOptions =[
        {value: 0, label: dict.female[props.lang] },
        {value: 1, label: dict.male[props.lang]},
    ]
    const ageOptions = [
        {value: 40, label: '< 45' },
        {value: 50, label: '45-52'},
        {value: 55, label: '53-57'},
        {value: 60, label: '58-62'},
        {value: 65, label: '63-67'},
        {value: 70, label: '> 67'}
    ]
    const smokingOptions =[
        {value: 0, label: dict.no[props.lang] },
        {value: 1, label: dict.yes[props.lang]},
    ]
    const countryOptions =[
        {value: 0, label: dict.lowRisk[props.lang] },
        {value: 1, label: dict.highRisk[props.lang]},
        {value: 2, label: dict.poland[props.lang]},
    ]
    const pressureOptions = [
        {value: 120, label: '< 130' },
        {value: 140, label: '130-149'},
        {value: 160, label: '150-169'},
        {value: 180, label: '170-179'},
        {value: 200, label: '>=180'},
    ]
    const diastolicOptions = [
        {value: 0, label: '< 110' },
        {value: 1, label: '>=110'}
    ]
    const cholesterolOptions = [
        {value: 4, label: cholUnit ? '< 175' : '< 4.5' },
        {value: 5, label: cholUnit ? '175-215' : '4.5-5.5'},
        {value: 5, label: cholUnit ? '215-250' : '5.5-6.5'},
        {value: 7, label: cholUnit ? '250-310' : '6.5-8.0'},
        {value: 9, label: cholUnit ? '>=310' : '>=8.0'},
    ]
    const ldlOptions = [
        {value: 0, label: '< '+(cholUnit ? '190' : '4.9') },
        {value: 1, label: '>='+(cholUnit ? '190' : '4.9') }
    ]
    const cholUnitOptions = [
        {value: 0, label: 'mg/dl' },
        {value: 1, label: 'mmol/L'}
    ]
    const diabetesDurationOptions = [
        {value: 0, label: '< 10' + dict.years[props.lang]},
        {value: 1, label: '10-20' + dict.years[props.lang]},
        {value: 2, label: '> 20'+dict.years[props.lang]},
    ]
    const diabetesComplicationsOptions =[
        {value: 0, label: dict.no[props.lang] },
        {value: 1, label: dict.yes[props.lang]},
    ]
    const kidneysWhichOptions = [
        {value: 0, label: dict.kidneysMild[props.lang]+'(GFR > 59 ml/min/1,73 m2)'},
        {value: 1, label: dict.kidneysModerate[props.lang]+'(GFR 30-59 ml/min/1,73 m2)'},
        {value: 2, label: dict.kidneysSevere[props.lang]+'(GFR < 30 ml/min/1,73 m2)'}
    ]

    return(
        <div>
            <header className="site-header">
                {dict.calculator[props.lang]}
            </header>
            <div className="row">
                <div className="flexChild margin subsection">
                    <header className="subheader narrowMargin">
                        {dict.input[props.lang]}
                    </header>

                    <div className="row">
                        <SelectControl
                            name={dict.gender[props.lang]}
                            value={user.gender}
                            options={genderOptions}
                            onChange={changeGender}
                        />

                        <SelectControl
                            name={dict.age[props.lang]}
                            value={user.age}
                            options={ageOptions}
                            onChange={changeAge}
                        />
                    </div>

                    <div className="row">
                        <SelectControl
                            name={dict.smoking[props.lang]}
                            value={user.smoking}
                            options={smokingOptions}
                            onChange={changeSmoking}
                        />

                        <SelectControl
                            name={dict.country[props.lang]}
                            value={user.country}
                            options={countryOptions}
                            onChange={changeCountry}
                        />

                        <InfoDialog
                            header={dict.countryHeader[props.lang]}
                            items={dict.countryList[props.lang]} 
                        />
                    </div>

                    <div className="row">
                        <SelectControl
                            name={dict.pressure[props.lang]}
                            value={user.pressure}
                            options={pressureOptions}
                            onChange={changePressure}
                        />

                        {user.pressure >=160 &&
                            <SelectControl
                                name={dict.pressureDia[props.lang]}
                                value={diastolic}
                                options={diastolicOptions}
                                onChange={changeDiastolic}
                            />
                        }
                    </div>
                
                    <div className="row">
                        <SelectControl
                            name={dict.cholesterol[props.lang]}
                            value={user.cholesterol}
                            options={cholesterolOptions}
                            onChange={changeCholesterol}
                        />

                        {user.cholesterol >=7  &&
                            <SelectControl
                                name={dict.cholesterolLDL[props.lang]}
                                value={ldl}
                                options={ldlOptions}
                                onChange={changeLdl}
                            />
                        }
                        <SelectControl
                            name={dict.cholesterolUnit[props.lang]}
                            value={cholUnit}
                            options={cholUnitOptions}
                            onChange={changeCholUnit}
                        />
                    </div>
                    
                    <br/><br/>

                    <List
                        className="table"
                        subheader={
                            <ListSubheader>
                                {dict.doYouHave[props.lang]}
                            </ListSubheader>}
                    >
                        <ListItem className="list-item">
                            <InfoDialog 
                                header={dict.cvEventHeader[props.lang]}
                                items={dict.cvEventList[props.lang]}
                            />
                            
                            {dict.cvEvent[props.lang]}

                            <ListItemSecondaryAction>
                                <Switch
                                    edge="end"
                                    checked={cvEvent}
                                    onChange={()=>setUser( {...user, cvEvent: !cvEvent})}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem className="list-item">
                            {dict.diabetes[props.lang]}
                            <ListItemSecondaryAction>
                                <Switch
                                    edge="end"
                                    checked={diabetes}
                                    onChange={()=>setUser( {...user, diabetes:!diabetes})}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>

                        {diabetes &&
                            <ListItem>
                                <SelectControl
                                    name={dict.diabetesHowLong[props.lang]}
                                    value={diabetesDuration}
                                    options={diabetesDurationOptions}
                                    onChange={changeDiabetesDuration}
                                />

                                <SelectControl
                                    name={dict.diabetesComplications[props.lang]}
                                    value={diabetesComplications}
                                    options={diabetesComplicationsOptions}
                                    onChange={changeDiabetesComplications}
                                />
                            </ListItem>
                        }

                        <ListItem className="list-item">
                            {dict.kidneys[props.lang]}
                            <ListItemSecondaryAction>
                                <Switch
                                    edge="end"
                                    checked={kidneys}
                                    onChange={()=>setUser( {...user, kidneys: !kidneys})}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>

                        {kidneys &&
                            <ListItem>
                                <SelectControl
                                    name={dict.kidnwysWhich[props.lang]}
                                    value={kidneysWhich}
                                    options={kidneysWhichOptions}
                                    onChange={changeKidneysWhich}
                                />
                            </ListItem>
                        }

                        <ListItem className="list-item">
                            {dict.fh[props.lang]}
                            <ListItemSecondaryAction>
                                <Switch
                                    edge="end"
                                    checked={fh}
                                    onChange={()=>setUser( {...user, fh: !fh})}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </div>

                <Result user ={user} lang={props.lang} />
            </div>
        </div>
    )
}
