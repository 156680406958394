import React, {useEffect} from 'react';
import { Link, useLocation} from "react-router-dom";
import '../App.css';
import {dict} from '../utils/dict'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { Language } from '../App';

export default function Footer(props: {lang: Language}) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])

  return (
      <footer className="footer full center row">
        <div className="footer full center row">
          <div>
            <b>{dict.follow[props.lang]}</b>          
            <List>  
              <a
                className="link"
                href="https://www.facebook.com/algocardio/"
                target="_blank"
                rel="noopener noreferrer"
                >
                <ListItem button>
                  <FacebookIcon className="icon"/>
                  <ListItemText primary="Facebook" />
                </ListItem>
              </a>
              <a
                className="link"
                href="https://www.linkedin.com/company/12661784/"
                target="_blank"
                rel="noopener noreferrer"
                >
                <ListItem button>
                  <LinkedInIcon className="icon"/>
                  <ListItemText primary="LinkedIn" />
                </ListItem>
              </a>
              <a
                  className="link"
                  href="https://youtu.be/puHguCv6xMI"
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  <ListItem button>
                    <YouTubeIcon className="icon"/>
                    <ListItemText primary="YouTube" />
                  </ListItem>
                </a>
            </List>
          </div>

          <div>
            <b>{dict.legal[props.lang]}</b>
            <List >
              <ListItem button><Link className="link" to="/privacy/">{dict.privacy[props.lang]}</Link></ListItem>
              <ListItem button><Link className="link" to="/terms/">{dict.terms[props.lang]}</Link></ListItem>
              <ListItem button><Link className="link" to="/cookies/">{dict.cookies[props.lang]}</Link></ListItem>
            </List>
          </div>
          
          </div>
          &copy; 2023 AlgoCardio Sp. z o.o.
      </footer>
  );
}

