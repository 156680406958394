import React from 'react';
import '../App.css';
import { Link } from "react-router-dom";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import logo from '../images/logo cale.png';
import GooglePlayEN from '../images/google-play-badge-EN.png'
import GooglePlayPL from '../images/google-play-badge-PL.png'
import AppStorePL from '../images/Download_on_the_App_Store_Badge_PL_RGB_blk_100317.svg'
import AppStoreEN from '../images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import { Language } from '../App';

export default function Header(props: {lang: Language, setLang(lang: Language):void}) {
  return (
    <header className="full row margin">

      <div className="center row justify"> 
        <Link to="/">
            <img src={logo} className="App-logo" alt="logo" />
        </Link>

        <FormControl variant='outlined' >
          <Select
            value={props.lang}
            onChange={e=>props.setLang(e.target.value as Language)}
            className="lang-picker"
            >
            <MenuItem value={'en'}>EN</MenuItem>
            <MenuItem value={'pl'}>PL</MenuItem>
          </Select>
        </FormControl>
      </div>
    </header>
  );
}

