import React, { useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Main from './components/Main';
import Policy from './components/Policy';
ReactGA.initialize('UA-152409631-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export type Language = 'en' | "pl"

function App() {
  const [lang, setLang] = useState<Language>('pl')

  return (
    <div className="App">
      <Router>
        <Header lang={lang} setLang={setLang}/>

        <Route path="/" exact component={()=><Main lang={lang}/>} />
        <Route path="/privacy/" component={()=><Policy policy='privacy' lang={lang}/>} />
        <Route path="/terms/" component={()=><Policy policy='terms' lang={lang}/>} />
        <Route path="/cookies/" component={()=><Policy policy='cookies' lang={lang}/>} />

        <Footer lang={lang}/>
      </Router>
    </div>
  );
}

export default App;
