import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import React from 'react';
import { Link } from "react-router-dom";
import { Language } from '../App';
import '../App.css';
import Risk from './Risk';
import {dict} from '../utils/dict';

type MainProps = {
    lang: Language
}

class Main extends React.Component <MainProps>{
    state = {
        snack: true,
    }

    close(){
        this.setState({
          snack: false
        });
    }

    render(){

    return (
        <main>
            <div className="section full row" >
                <Risk lang={this.props.lang}/>
            </div>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={this.state.snack}
                className="cookieBar"
                message={<span id="message-id">{dict.cookiesBar[this.props.lang]}</span>}
                action={[
                    <Button
                        key="cookies" 
                        color="secondary"
                        size="small" 
                        onClick={()=>this.close()}
                    >
                        <Link className="link" to="/cookies/">
                            {dict.cookies[this.props.lang]}
                        </Link>
                    </Button>,
                    <Button
                        key="ok" 
                        size="small" 
                        onClick={()=>this.close()}
                    >
                        <p style={{color: 'green'}}>OK</p>
                    </Button>,
                ]}
                />
        </main>
    );
    }
}

export default Main;
